<a
  id="expressBtnAnimated"
  routerLink="/express"
  (click)="setExpressSearchCase()"
  role="button"
  aria-label="Go to Express Search"
  tabindex="0"
  class="_x_bg-expressLabel _x_flex _x_h-14 _x_w-[112px] _x_items-center _x_overflow-hidden _x_rounded-32 _x_py-1">
  <div
    class="_x_bg-expressLabel _x_relative _x_flex _x_h-full _x_w-full _x_items-center _x_overflow-hidden _x_font-bold _x_text-3 _x_text-dark">
    <span class="express _x_absolute _x_w-full _x_text-center _x_opacity-0">ექსპრესი</span>
    <span class="time _x_absolute _x_w-full _x_text-center _x_opacity-0">35-50 წუთში</span>
  </div>
</a>
