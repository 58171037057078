<div
  class="_x_fixed _x_left-0 _x_top-0 _x_z-[51] _x_flex _x_h-screen _x_w-screen _x_flex-col _x_items-center _x_justify-between _x_gap-y-20 _x_overflow-auto _x_bg-white _x_px-8 md:!_x_left-50prc md:!_x_top-50prc md:_x_h-auto md:_x_max-h-[580px] md:_x_min-h-[330px] md:_x_w-full md:_x_max-w-[600px] md:_x_translate-x-[-50%] md:_x_translate-y-[-50%] md:_x_gap-0 md:_x_rounded-12 md:_x_px-0"
  [ngClass]="
    currentQuestion && userFeedbackService.getFeedbackView() === feedbackView.orderDetails
      ? '_x_pb-10 _x_pt-10 md:_x_pb-15 md:_x_pt-0'
      : '_x_pb-[76px]'
  ">
  <header
    *ngIf="userFeedbackService.getFeedbackView() !== feedbackView.orderDetails || !isUpTo1023px"
    class="_x_border-b-1 _x_border-dark-200 md:_x_mb-15 md:_x_flex md:_x_w-full md:_x_items-center md:_x_justify-between md:_x_px-8 md:_x_py-6">
    <img class="_x_hidden md:_x_block md:_x_w-54" src="./assets/img/extra-logo.png" alt="Logo of Extra.ge" />
    <button
      type="button"
      (click)="rejectReviewQuestions()"
      class="_x_absolute _x_right-0 _x_top-3 _x_flex _x_items-center _x_justify-center !_x_bg-white _x_p-[14px] md:_x_static md:_x_h-12 md:_x_w-12 md:_x_cursor-pointer md:_x_p-0"
      aria-label="Close modal">
      <img src="./assets/img/close-icon.svg" alt="Close icon" />
    </button>
  </header>

  <main
    class="_x_flex _x_w-full _x_flex-col _x_items-center md:_x_max-w-unset md:_x_px-8"
    [ngClass]="
      userFeedbackService.getFeedbackView() !== feedbackView.orderDetails || !isUpTo1023px
        ? '_x_max-w-200'
        : '_x_max-w-unset'
    ">
    <img
      *ngIf="userFeedbackService.getFeedbackView() !== feedbackView.orderDetails || !isUpTo1023px"
      class="_x_mb-12 _x_block md:_x_hidden"
      src="./assets/img/extra-logo.png"
      alt="Logo of Extra.ge" />
    <div
      *ngIf="userFeedbackService.getFeedbackView() === feedbackView.feedback"
      class="_x_mb-12 _x_flex _x_items-center _x_gap-x-4 md:_x_mb-16 md:_x_w-full">
      <div class="_x_h-2 _x_w-[156px] _x_overflow-hidden _x_rounded-8 _x_bg-gray-600 md:_x_w-full md:_x_max-w-[540px]">
        <!--        progress background    -->
        <span
          class="_x_block _x_h-full _x_rounded-8"
          [style.width]="inProgressPercent"
          style="background: linear-gradient(90.01deg, #5786d9 -19.25%, #ac75ff 90.07%)">
          <!--          progress    -->
        </span>
      </div>
      <span class="_x_font-medium _x_text-2 _x_text-dark-700">
        {{ answeredCount + currentQuestionIndex + 1 }}/{{ totalQuestionsCount }}
      </span>
    </div>

    <app-user-feedback-questions
      *ngIf="
        (currentQuestion && userFeedbackService.getFeedbackView() === feedbackView.feedback) ||
        userFeedbackService.getFeedbackView() === feedbackView.orderDetails
      "
      class="_x_w-full"
      [question]="currentQuestion"
      (submitAnswer)="submitAnswer($event)">
    </app-user-feedback-questions>

    <ng-container *ngIf="userFeedbackService.getFeedbackView() === feedbackView.error">
      <div class="_x_flex _x_flex-col _x_items-center _x_gap-6 md:_x_mb-10">
        <img class="_x_w-fit" src="../../../assets/img/svg/error.svg" alt="icon of Error" />
        <h4 class="_x_mt-6 _x_text-center _x_font-bold _x_text-5 _x_text-dark">დაფიქსირდა შეცდომა</h4>
        <p class="_x_text-center _x_font-medium _x_text-3 _x_text-dark-500">
          შენი პასუხი ვერ გაიგზავნა. გთხოვ, უპასუხო თავიდან
        </p>
      </div>

      <div
        class="_x_fixed _x_bottom-0 _x_left-50prc _x_flex _x_w-full _x_translate-x-[-50%] _x_justify-center _x_border-t-1 _x_border-dark-200 _x_bg-white _x_pt-6 md:_x_border-t-0">
        <button
          type="button"
          (click)="closeModal()"
          class="_x_mb-6 _x_h-20 _x_w-calc-w-full-32 _x_max-w-200 _x_rounded-8 _x_bg-purple _x_font-bold _x_text-3 _x_text-white _x_transition-all _x_duration-200 hover:_x_bg-purple-600 md:_x_mb-12 md:_x_w-full md:_x_max-w-[568px] md:_x_cursor-pointer">
          დახურვა
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="userFeedbackService.getFeedbackView() === feedbackView.success">
      <div class="_x_flex _x_flex-col _x_items-center _x_gap-6 md:_x_mb-10">
        <img class="_x_w-fit" src="../../../assets/img/svg/success-event.svg" alt="icon of Success" />
        <h4 class="_x_mt-6 _x_text-center _x_font-bold _x_text-5 _x_text-dark">შეფასება წარმატებით გაიგზავნა</h4>
        <p class="_x_text-center _x_font-medium _x_text-3 _x_text-dark-500">მადლობა შეფასებაში მონაწილეობისთვის</p>
      </div>

      <div
        class="_x_fixed _x_bottom-0 _x_left-50prc _x_flex _x_w-full _x_translate-x-[-50%] _x_justify-center _x_border-t-1 _x_border-dark-200 _x_bg-white _x_pt-6 md:_x_border-t-0">
        <button
          type="button"
          (click)="closeModal(true)"
          class="_x_mb-6 _x_h-20 _x_w-calc-w-full-32 _x_max-w-200 _x_rounded-8 _x_bg-purple _x_font-bold _x_text-3 _x_text-white _x_transition-all _x_duration-200 hover:_x_bg-purple-600 md:_x_mb-12 md:_x_w-full md:_x_max-w-[568px] md:_x_cursor-pointer">
          დახურვა
        </button>
      </div>
    </ng-container>
  </main>

  <footer></footer>
</div>
