export const environment = {
  production: false,
  develop: false,
  staging: true,
  cmsURL: 'https://catalog.staging.extra.ge/',
  customersURL: 'https://customer.staging.extra.ge/',
  reportingURL: 'https://reporting.staging.extra.ge/',
  paymentURL: 'https://payment.staging.extra.ge/',
  orderingURL: 'https://ordering.staging.extra.ge/',
  basketUrl: 'https://basket.staging.extra.ge/',
  apiUrl: 'https://identity.staging.extra.ge/',
  emailUrl: 'https://notification.staging.extra.ge/',
  issuerUrl: 'https://identity.staging.extra.ge',
  marketingURL: 'https://marketing.staging.extra.ge/',
  tradeInUrl: 'https://tradein-api.staging.extra.ge/',
  cdnUrl: 'https://cdn.staging.extra.ge/',
  baseUrl: 'https://staging.extra.ge',
  juridicalUrl: 'https://juridicalidentity.staging.extra.ge/',
  juridicalIssuerUrl: 'https://juridicalidentity.staging.extra.ge',
  adultCallback: 'catalog/satamashoebi',
  'mercury-api-url': 'https://mercury.staging.extra.ge/',
  'wall-e': 'https://wall-e.staging.extra.ge/',
  deliveryCalculationApi: 'https://delivery-datecalculation-api.staging.extra.ge/',
  deliveryIntergrationURL: 'https://delivery-integration-api.staging.extra.ge/'
};
