import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class ProductsHttp {
  constructor(private api: ApiService) {}

  getCustomerProductCountByUserId() {
    return this.api.get(`api/products/get-product-count-byuserid`, {}, 'cmsURL');
  }

  setProductServiceAsPending(data): Observable<any> {
    return this.api.post('api/products/set-product-service-pending', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  getProductById(id): Observable<any> {
    return this.api.get(`api/products/${id}/b2c`, {}, 'cmsURL');
  }

  // old
  // getProductDetailsById(id, slug): Observable<any> {
  //   return this.api.get(`api/products/${id}/${slug}/details`, {}, 'cmsURL')
  // }

  // new
  getProductDetailsById(id, slug): Observable<any> {
    return this.api.get(`api/products/${slug}/${id}`, {}, 'cmsURL');
  }

  getProductAutocompletionItems(searchTerm: string) {
    return this.api.get<any[]>(
      `api/products/get-autocompleteproductname`,
      {
        input: searchTerm
      },
      'cmsURL'
    );
  }

  uploadSearchImage(data) {
    return this.api.upload(`eve/scan`, data, {}, 'wall-e');
  }

  getImageByExternalId(externalId: string) {
    return this.api.get(`eve/scan/${externalId}`, {}, 'wall-e');
  }

  getFeaturesByModel(brand: string, model: string, categoryId: any, type: number = 2) {
    return this.api.post<any[]>(
      `api/products/get-productspecification`,
      {
        brand,
        model,
        type,
        categoryId
      },
      { requestId: this.getGuidId() },
      'cmsURL'
    );
  }

  getproductidsby(pageNumber = 1, pageSize = 10, byType: string): Observable<any> {
    return this.api.get(
      'api/products/getproductidsby',
      {
        pageNumber: pageNumber,
        pageSize: pageSize,
        byType: byType
      },
      'cmsURL'
    );
  }

  getproductquanatity(id: number): Observable<any> {
    return this.api.get(
      'api/products/productquantity',
      {
        productId: id
      },
      'cmsURL'
    );
  }

  getproductsbyids(adsArr = []): Observable<any> {
    return this.api.post('products/gimme', adsArr, {}, 'mercury-api-url');
  }

  getLandingFilteredProducts(adsArr: any): Observable<any> {
    return this.api.post('api/products/getfilteredproductsforlandingpage', adsArr, {}, 'cmsURL');
  }

  getProductSuggestions(searchText: string) {
    return this.api.post('api/products/suggestions/top-ten', { searchText }, { requestId: this.getGuidId() }, 'cmsURL');
  }

  getProductSuggestionsGoGo(searchText: string) {
    return this.api.post('search/go-go', { searchText }, {}, 'mercury-api-url');
  }

  duplicateProduct(id: string): Observable<any> {
    return this.api.post(
      'api/products/duplicateproduct',
      {
        productId: id
      },
      { requestId: this.getGuidId() },
      'cmsURL'
    );
  }

  getProducts(
    pageNumber = 1,
    pageSize = 10,
    status,
    userIds,
    CategoryId,
    searchValue?,
    isVoucher: boolean = false,
    sortBy?: ProductFilterSortBy,
    sortByDescending?: boolean,
    MinExtraCommissionFeePercent?: number | string,
    MaxExtraCommissionFeePercent?: number | string,
    MinDiscountExtraCommissionFeePercent?: number | string,
    MaxDiscountExtraCommissionFeePercent?: number | string
  ): Observable<any> {
    return this.api.get(
      'api/products/b2c',
      {
        pageNumber: pageNumber,
        pageSize: pageSize,
        status: status,
        userIds,
        categoryId: CategoryId,
        isVoucher,
        searchValue: searchValue && searchValue,
        sortBy,
        sortByDescending,
        MinExtraCommissionFeePercent,
        MaxExtraCommissionFeePercent,
        MinDiscountExtraCommissionFeePercent,
        MaxDiscountExtraCommissionFeePercent
      },
      'cmsURL'
    );
  }

  getProductsByIds(pageNumber = 1, pageSize = 10, ids): Observable<any> {
    return this.api.get(
      'api/products/b2c',
      {
        pageNumber: pageNumber,
        pageSize: pageSize,
        ids: ids
      },
      'cmsURL'
    );
  }

  getReport(globalFilter, pCategoryId, pStatus, pUserId, pStartDate, pEndDate, path): Observable<any> {
    return this.api.get(
      'api/productreports/' + path,
      {
        CategoryId: pCategoryId,
        Status: pStatus,
        UserId: pUserId,
        searchValue: globalFilter,
        startDate: pStartDate,
        endDate: pEndDate
      },
      'cmsURL',
      'blob'
    );
  }

  getProductsCustomerC2c(pageNumber = 1, pageSize = 10, status, searchValue?): Observable<any> {
    return this.api.get(
      'api/products/customer-c2c',
      {
        pageNumber: pageNumber,
        pageSize: pageSize,
        status: status,
        searchValue: searchValue && searchValue
      },
      'cmsURL'
    );
  }

  getManufacturerCountriess(pageNumber = 1, pageSize = 1000, searchValue?): Observable<any> {
    return this.api.get(
      'api/manufacturercountries',
      {
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchValue: searchValue && searchValue
      },
      'cmsURL'
    );
  }

  uploadProductImage(data): Observable<any> {
    return this.api.upload('api/upload/productimage', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  updateProductDetailsMultiple(data, path): Observable<any> {
    return this.api.upload('api/productimports/' + path, data, { requestId: this.getGuidId() }, 'cmsURL', 'blob');
  }

  uploadProductImageC2C(data): Observable<any> {
    return this.api.upload('api/upload/productimagec2c', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  createbyadministrator(data): Observable<any> {
    return this.api.post('api/products/createbyadministrator', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  updateByAdministrator(data): Observable<any> {
    return this.api.put('api/products/updatebyadministrator', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  publishC2C(data): Observable<any> {
    return this.api.put('api/products/publishc2c', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  deleteProductAsset(data): Observable<any> {
    return this.api.delete(
      'api/products/deleteproductasset',
      {
        requestId: this.getGuidId(),
        AssetName: data.AssetName,
        CorrelationId: data.CorrelationId,
        ProductId: data.ProductId
      },
      'cmsURL'
    );
  }

  deleteProductC2c(productId): Observable<any> {
    return this.api.delete(
      'api/products/deletec2c',
      {
        requestId: this.getGuidId(),
        productId: productId
      },
      'cmsURL'
    );
  }

  updateDraft(data, sendSMS = true): Observable<any> {
    return this.api.put(
      'api/products/updatec2c',
      { product: data, sendSMS },
      { requestId: this.getGuidId() },
      'cmsURL'
    );
  }

  createDraftC2C(data): Observable<any> {
    return this.api.post('api/products/createdraftc2c', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  prolongC2C(id): Observable<any> {
    return this.api.post(
      'api/products/prolongc2cproduct',
      {
        productId: id
      },
      { requestId: this.getGuidId() },
      'cmsURL'
    );
  }

  changeProductStatus(data): Observable<any> {
    return this.api.put('api/products/product/status', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  private getGuidId(): string {
    return Guid.create().toString();
  }

  unpublishC2CStatement(id): Observable<any> {
    return this.api.post('api/products/hidec2cstatement', { productId: id }, { requestId: this.getGuidId() }, 'cmsURL');
  }

  publishC2CStatement(id): Observable<any> {
    return this.api.post(
      'api/products/unhidec2cstatement',
      { productId: id },
      { requestId: this.getGuidId() },
      'cmsURL'
    );
  }

  checkExpressProductStock(data) {
    return this.api.post(
      `api/ExpressIntegration/check/products`,
      data,
      { requestId: this.getGuidId() },
      'deliveryIntergrationURL'
    );
  }
}

export enum ProductFilterSortBy {
  None = 0,
  ExtraCommissionFeePercent = 1,
  DiscountExtraCommissionFeePercent = 2
}
