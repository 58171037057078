<div *ngIf="orderDetails$ | async as orderDetails">
  <div>
    <a
      (click)="userFeedbackService.toggleFeedbackView()"
      class="_x_mb-10 _x_flex _x_items-center _x_gap-4 _x_text-dark md:_x_mb-8">
      <i
        class="_x_icon-arrow-left _x_flex _x_h-12 _x_w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_rounded-8 _x_border-0 _x_bg-white _x_text-7 _x_text-dark">
      </i>
      <h5 class="_x_font-bold _x_text-5">შეკვეთის ნომერი:</h5>
      <span class="_x_font-regular _x_text-5"> #{{ orderDetails?.externalId }} </span>
    </a>

    <div class="_x_flex _x_flex-col _x_items-start _x_gap-4 _x_text-3">
      <div class="_x_flex _x_items-center _x_gap-2 _x_text-dark">
        <h5 class="_x_font-medium">შეკვეთის თარიღი:</h5>
        <span class="_x_font-semibold">
          {{ orderDetails.createTime | date: 'dd MMMM, yyyy' }}
        </span>
      </div>

      <div class="_x_flex _x_items-center _x_gap-2 _x_font-medium _x_text-dark-700">
        <h5 class="_x_font-medium">სტატუსი:</h5>
        <span>
          {{ orderLineStatusNaming[orderDetails?.orderForCustomerViewType] }}
        </span>
      </div>
    </div>

    <ng-container *ngFor="let groupedLine of orderDetails?.orderLines">
      <ng-container
        *ngFor="
          let voucherId of groupedLine.lines[0].voucherType
            ? groupedLine.lines[0].voucherIds.length
              ? groupedLine.lines[0].voucherIds
              : (groupedLine.lines[0].productCount | numberToArray)
            : [1]
        ">
        <ng-container *ngFor="let product of groupedLine.lines">
          <div
            *ngIf="!product.priceDifference"
            class="_x_mt-8 _x_flex _x_items-center _x_gap-10 _x_py-8 _x_pl-10 _x_pr-12 md:_x_gap-6 md:_x_px-8">
            <img
              class="_x_h-30 _x_w-30 _x_object-cover md:_x_h-40 md:_x_w-40"
              [src]="product.productAssetImage.small"
              [alt]="product.productTitle" />

            <div class="_x_flex _x_w-full _x_flex-col md:_x_flex-row">
              <div class="_x_mb-6 _x_text-left md:_x_mb-0 md:_x_w-[30%]">
                <h5 class="_x_font-bold _x_text-3 _x_text-dark">
                  {{ product.productTitle }}
                  <ng-container *ngIf="groupedLine?.lines?.[0]?.voucherType"
                    >({{ groupedLine.lines[0].voucherType | voucherName }})</ng-container
                  >
                </h5>
              </div>

              <div
                class="_x_flex _x_items-center _x_justify-between _x_gap-4 _x_font-medium _x_text-3 _x_text-dark-800 md:_x_w-[70%]">
                <p class="_x_flex _x_flex-col md:_x_w-[50%] md:_x_items-center">
                  <span class="_x_block md:_x_hidden"> რ-ბა </span>
                  <span class="_x_hidden md:_x_block"> რაოდენობა </span>
                  <span class="_x_mt-4 _x_text-center _x_font-bold _x_text-4 _x_text-dark">
                    {{ groupedLine.lines[0].voucherType ? 1 : product.productCount }}
                  </span>
                </p>
                <div class="_x_flex _x_flex-col md:_x_w-[50%] md:_x_items-end">
                  ღირებულება

                  <p class="_x_mt-4 _x_text-right _x_font-bold _x_text-4 _x_text-dark">{{ product.price }} ₾</p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="_x_rounded-8 _x_bg-gray-200 _x_px-8 _x_py-12 md:_x_flex md:_x_justify-between">
    <div class="_x_flex _x_w-full _x_flex-col _x_gap-y-6 md:_x_min-w-[334px]">
      <div class="_x_flex _x_items-center _x_justify-between _x_gap-x-10 _x_font-medium _x_text-4 _x_text-dark">
        <p>
          პროდუქტი
          <span *ngIf="orderDetails.productCount">({{ orderDetails.productCount }})</span>
        </p>
        <p>{{ orderDetails.subTotalPrice }} ₾</p>
      </div>
      <div
        *ngIf="orderDetails.shippingPrice && orderDetails.orderLines[0].lines[0].voucherType !== voucherType.electric"
        class="_x_flex _x_items-center _x_justify-between _x_gap-x-10 _x_font-medium _x_text-4 _x_text-dark">
        <p>მიტანის ღირებულება</p>
        <p>{{ orderDetails.shippingPrice }} ₾</p>
      </div>
      <div class="_x_flex _x_items-center _x_justify-between _x_gap-x-10 _x_font-medium _x_text-4 _x_text-dark">
        <p class="_x_text-left">გადახდის მეთოდი</p>
        <p class="_x_text-right">
          <ng-container *ngIf="orderDetails.isCombinedPayment; else standartPaymentMethodsTemplate">
            კომბინირებული
          </ng-container>
          <ng-template #standartPaymentMethodsTemplate>
            <ng-container
              *ngIf="
                orderDetails.paymentType === paymentType.CashOnHand &&
                  orderDetails.orderDeliveryType === orderDeliveryType.Pickup;
                else defaultNamingForPaymentMethods
              ">
              გადაიხადე ადგილზე
            </ng-container>
            <ng-template #defaultNamingForPaymentMethods>
              {{ orderDetails | paymentLabel: paymentMethod }}
            </ng-template>
          </ng-template>
        </p>
      </div>
      <div class="_x_flex _x_items-center _x_justify-between _x_gap-x-5 _x_font-bold _x_text-5 _x_text-dark">
        <p>სულ თანხა</p>
        <p>{{ orderDetails.totalPrice }} ₾</p>
      </div>
    </div>
  </div>
</div>
