import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExpressOrderFinancialType, OrderLineForCustomerViewType } from '../../../store/models/orders.model';
import { OrderDebtService } from '../../../services/order-debt.service';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { orderDebtModalInputs } from '../global-modal-wrapper/utility/global-modals.model';
import { GlobalModalService } from '../../../services/global-modal-service';

@Component({
  selector: 'app-order-debt',
  templateUrl: './order-debt.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterLink],
  standalone: true
})
export class OrderDebtComponent implements orderDebtModalInputs {
  @Input() orderWithDebt = null;
  public orderLineStatusNaming = {
    [OrderLineForCustomerViewType.Recived]: 'მივიღეთ',
    [OrderLineForCustomerViewType.Waiting]: 'ველოდებით',
    [OrderLineForCustomerViewType.Process]: 'ვამუშავებთ',
    [OrderLineForCustomerViewType.Collect]: 'ვაგროვებთ',
    [OrderLineForCustomerViewType.Prepare]: 'ვამზადებთ',
    [OrderLineForCustomerViewType.Bring]: 'მოგვაქვს',
    [OrderLineForCustomerViewType.DeliveredC]: 'ჩაბარებულია',
    [OrderLineForCustomerViewType.DeliveredP]: 'ფოსტაშია',
    [OrderLineForCustomerViewType.Cancelled]: 'გაუქმებულია'
  };
  orderLineForCustomerViewTypeEnum = OrderLineForCustomerViewType;
  expressOrderType = ExpressOrderFinancialType;

  constructor(
    public orderDebtService: OrderDebtService,
    public globalModalService: GlobalModalService
  ) {}
}
