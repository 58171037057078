import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ExpressService } from '../../../services/express.service';

@Component({
  selector: 'app-express-button',
  templateUrl: './express-button.component.html',
  styleUrls: ['./express-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpressButtonComponent {
  constructor(private expressService: ExpressService) {}

  setExpressSearchCase(): void {
    this.expressService.addSearchCase();
  }
}
