<div class="_x_flex _x_flex-col _x_gap-y-6 _x_text-center">
  <ng-container *ngIf="userFeedbackService.getFeedbackView() === feedbackView.feedback; else showOrderDetailedPage">
    <ng-container *ngIf="question">
      <h4 class="_x_font-bold _x_text-5 _x_text-dark">{{ question.question }}</h4>
      <p class="_x_flex _x_items-center _x_justify-center _x_gap-2 _x_font-medium _x_text-3 _x_text-dark-700">
        შეკვეთის ნომერი:
        <a (click)="toggleFeedBackView()" class="_x_font-bold _x_text-link-blue md:_x_cursor-pointer">
          #{{ userFeedbackService.getOrderExternalId() }}</a
        >
      </p>
      <p class="_x_mt-2 _x_font-medium _x_text-3 _x_text-dark-500">{{ question.description }}</p>

      <div
        *ngIf="question.answerType === reviewAnswerType.Rating"
        class="_x_mt-5 _x_flex _x_justify-center _x_gap-6 md:_x_mt-10">
        <div
          (click)="toggleActiveRatingAnswer(i)"
          *ngFor="let rating of ratings; let i = index"
          class="_x_flex _x_w-27 _x_flex-col _x_items-center _x_gap-1 md:_x_cursor-pointer">
          <div [innerHTML]="(activeIndex === i ? rating.iconActive : rating.icon) | safe: 'html'"></div>
          <span
            [ngClass]="activeIndex === i ? '_x_opacity-100' : '_x_opacity-0'"
            class="_x_font-medium _x_text-2 _x_text-dark-700">
            {{ rating.text }}
          </span>
        </div>
      </div>

      <textarea
        aria-label="review textarea"
        *ngIf="question.answerType === reviewAnswerType.Text"
        name="service review"
        [formControl]="textAnswerControl"
        maxlength="255"
        class="font-medium _x_mt-10 _x_h-60 _x_w-full _x_resize-none _x_rounded-4 _x_border _x_border-dark-200 _x_p-6 _x_text-3 _x_text-dark-700 _x_outline-none md:_x_mb-10"
        placeholder="დაგვიტოვე კომენტარი აქ"></textarea>

      <div
        class="_x_fixed _x_bottom-0 _x_left-50prc _x_flex _x_w-full _x_translate-x-[-50%] _x_justify-center _x_border-t-1 _x_border-dark-200 _x_bg-white _x_pt-6 md:_x_border-t-0">
        <button
          type="button"
          (click)="emitAnswer()"
          [disabled]="sendButtonEnableState"
          [ngClass]="
            !sendButtonEnableState
              ? '_x_bg-purple hover:_x_bg-purple-600 md:_x_cursor-pointer'
              : '_x_bg-purple-400 md:_x_cursor-not-allowed'
          "
          class="_x_mb-6 _x_h-20 _x_w-calc-w-full-32 _x_max-w-200 _x_rounded-8 _x_font-bold _x_text-3 _x_text-white _x_transition-all _x_duration-200 md:_x_mb-12 md:_x_w-full md:_x_max-w-[568px]">
          გაგზავნა
        </button>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #showOrderDetailedPage>
    <app-user-feedback-order-details></app-user-feedback-order-details>
  </ng-template>
</div>
