import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Unsubscriber } from 'lib-core';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsMobileService extends Unsubscriber {
  public isMobileDevice = new BehaviorSubject<boolean>(true);
  public isChatFullScreen = new BehaviorSubject<boolean>(true);
  public isUpTo1023px = new BehaviorSubject<boolean>(true);

  constructor(
    private observer: BreakpointObserver,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super();
    if (this.getPlatformIsBrowser) {
      this.observer
        .observe('(max-width: 768px)')
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.isMobileDevice.next(result.matches);
        });

      this.observer
        .observe('(max-width: 554px)')
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.isChatFullScreen.next(result.matches);
        });

      this.observer
        .observe('(max-width: 1023px)')
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.isUpTo1023px.next(result.matches);
        });
    }
  }

  get getPlatformIsBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.isMobileDevice.complete();
    this.isChatFullScreen.complete();
  }
}
