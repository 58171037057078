import { isPlatformBrowser, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafePipeModule } from '../../../shared/pipe/safe.pipe';
import { UserFeedbackOrderDetailsComponent } from '../user-feedback-order-details/user-feedback-order-details.component';
import {
  feedbackView,
  ReviewAnswerType,
  ReviewQuestionsInterface,
  UserFeedbackService
} from '../user-feedback.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FEEDBACK_ICONS } from '../user-feedback.constants';

@Component({
  selector: 'app-user-feedback-questions',
  templateUrl: './user-feedback-questions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, NgIf, SafePipeModule, ReactiveFormsModule, FormsModule, NgClass, UserFeedbackOrderDetailsComponent]
})
export class UserFeedbackQuestionsComponent {
  @Input() question: ReviewQuestionsInterface;
  @Output() submitAnswer = new EventEmitter<any>();

  public textAnswerControl = new FormControl('');
  public activeIndex: number | null = null;
  public feedbackView = feedbackView;
  public reviewAnswerType = ReviewAnswerType;
  public ratings = FEEDBACK_ICONS;
  private ratingAnswerControl = new FormControl(null);

  constructor(public userFeedbackService: UserFeedbackService) {}

  get sendButtonEnableState(): boolean {
    return !Boolean(
      this.question.skippable ||
        (this.question.answerType === ReviewAnswerType.Text && this.textAnswerControl.value?.trim()) ||
        (this.question.answerType === ReviewAnswerType.Rating && this.ratingAnswerControl.value)
    );
  }

  public toggleActiveRatingAnswer(index: number): void {
    this.activeIndex = this.activeIndex === index ? null : index; // Toggle the same item or set new
    this.ratingAnswerControl.setValue(index.toString());
  }

  public toggleFeedBackView(): void {
    this.userFeedbackService.toggleFeedbackView();
  }

  public emitAnswer() {
    let dataToEmit = {
      reviewQuestionId: this.question.id,
      orderExternalId: this.userFeedbackService.getOrderExternalId()
    };
    if (this.question.answerType === ReviewAnswerType.Text) {
      dataToEmit['textAnswer'] = this.textAnswerControl.value?.trim() || null;
    } else {
      dataToEmit['ratingAnswer'] = this.ratingAnswerControl.value;
    }
    this.submitAnswer.emit(dataToEmit);
    this.textAnswerControl.reset();
    this.ratingAnswerControl.reset();
    this.activeIndex = null;
  }
}
