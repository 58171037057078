import { Injectable, signal } from '@angular/core';
import { OrderingHttp } from 'lib-core';
import { BehaviorSubject, filter, Observable, of, Subject, switchMap } from 'rxjs';
import * as fromUser from '../store/models/user.model';
import { UserModel } from '../store/models/user.model';
import { select, Store } from '@ngrx/store';
import { getUserData } from '../store/reducers/user.reducer';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { UserTypeService } from './user-type.service';
import { UserDataInterface } from '../shared/models/interfaces/user-data.interface';
import { LastOrderInterface } from '../shared/models/interfaces/last-order.interface';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public isUserImpersonated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private user$: Observable<UserDataInterface> = this.store.pipe(
    select(getUserData),
    filter(user => user !== null && !user.loading),
    map(user => this.transFormDataToUerDataInterface(user, !this.userTypeService.isUserLegalEntity, true))
  );
  public userInitStatus = signal<boolean>(false);

  constructor(
    private orderingHttp: OrderingHttp,
    private store: Store<fromUser.UserModel>,
    private userTypeService: UserTypeService
  ) {}

  public setImpersonatedUser(value: boolean): void {
    this.isUserImpersonated$.next(value);
  }

  public getUserData(): Observable<UserDataInterface> {
    return this.orderingHttp.getDraft().pipe(
      takeUntil(this.destroy$),
      switchMap(draftData => {
        if (draftData.data && Object.keys(draftData.data).length > 0) {
          return of(
            this.transFormDataToUerDataInterface(draftData.data, !this.userTypeService.isUserLegalEntity, false)
          );
        } else {
          return this.user$;
        }

        // ეს იქნება სანახავი, შეიძლება აქაც გახდეს ამის დამატება საჭირო
        // this.form.get('locationId').setValue(this.isVoucher && this.voucherTypeValue === VoucherType.electric ? 1 : res.locationId);
        // this.form.get('location').setValue(this.isVoucher && this.voucherTypeValue === VoucherType.electric ? 1 : res.location);
      }),
      catchError(error => {
        console.error('Error fetching draft:', error);
        return this.user$;
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private transFormDataToUerDataInterface(
    data: UserModel | LastOrderInterface,
    isIndividual: boolean,
    isUserModel: boolean
  ): UserDataInterface {
    if (isUserModel) {
      return {
        firstName: isIndividual ? data?.firstName : (data as UserModel)?.juridicalTitle,
        lastName: data?.lastName,
        personalNumber: isIndividual ? (data as UserModel)?.personalNumber : (data as UserModel)?.juridicalId,
        phoneNumber: (data as UserModel)?.phoneNumber
      };
    } else {
      return {
        firstName: (data as LastOrderInterface)?.firstName,
        lastName: (data as LastOrderInterface)?.lastName,
        personalNumber: (data as LastOrderInterface)?.personalNumber,
        phoneNumber: (data as LastOrderInterface)?.phoneNumber
      };
    }
  }
}
