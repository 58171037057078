import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { OverflowHandlerService } from './overflow-handler.service';
import { GlobalModalService } from './global-modal-service';

@Injectable({
  providedIn: 'root'
})
export class OrderDebtService {
  isPaymentModalOpen = false;
  isOrderDebtModalOpen = false;
  isOrderDebtSeenFromHome = false;
  infoForPayment;
  userDebt = new BehaviorSubject(null);

  constructor(
    private router: Router,
    private overflowHandlerService: OverflowHandlerService,
    private globalModalService: GlobalModalService
  ) {}

  handlePaymentSuccess(paymentData) {
    if (paymentData?.href) {
      const IPayRedirect = paymentData.href;
      window.location.href = IPayRedirect;
    } else if (paymentData) {
      this.closeDebtPaymentModal();
      this.globalModalService.close();
      this.userDebt.next(null);
      this.router.navigate(['/checkout', 'success'], {
        queryParams: {
          isDebtPayment: true
        }
      });
    }
  }

  closeDebtPaymentModal() {
    this.isPaymentModalOpen = false;
    if (!this.isOrderDebtModalOpen) {
      this.showOverFlow();
    }
  }

  openDebtPaymentModal() {
    this.isPaymentModalOpen = true;
    this.hideOverflow();
  }

  hideOverflow() {
    this.overflowHandlerService.hide();
  }

  showOverFlow() {
    this.overflowHandlerService.show();
  }

  openPaymentModal(item) {
    this.infoForPayment = {
      selectedOrderPaymentId: item.userOrderFinancial?.userOrderFinancialId,
      orderDebt: item.userOrderFinancial?.amount
    };
    this.openDebtPaymentModal();
  }
}
