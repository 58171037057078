import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BasketItem } from '../store/models/basket.model';
import { UserModel } from '../store/models/user.model';
import { Observable, skip } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromUser from './../store/models/user.model';
import * as fromAuth from './../store/models/auth.model';
import { getUserData } from '../store/reducers/user.reducer';
import { getUserBasket, getUserBasketData } from '../store/reducers/basket.reducer';
import { environment } from 'src/environments/environment';

export enum VisilabsEvents {
  productPageView,
  categoryPageView,
  searchResultPageView,
  userLogIn,
  userSignUp,
  basketUpdateAndView,
  wishlistUpdate,
  bannerDisplay,
  bannerClick,
  genericPageView
}

@Injectable({
  providedIn: 'root'
})
export class VisilabsService {
  private renderer: Renderer2;
  private events: { name: VisilabsEvents; args: any[] }[] = [];
  private scriptLoaded: boolean = false;
  user$: Observable<UserModel> = this.store.pipe(select(getUserData));
  private userId = null;
  public userRegisered: boolean = false;
  basket$ = this.store.pipe(select(getUserBasket));
  basket;
  // TO BE CONSIDERED
  // events are triggered only on category and search page views. in case of merchant's it does not, also not for sets. could call for the categorypage view and send SETID but it could be duplicate?!

  constructor(
    private rendererFactory: RendererFactory2,
    private store: Store<fromAuth.AuthModel | fromUser.UserModel>
  ) {
    if (environment.production) {
      this.renderer = this.rendererFactory.createRenderer(null, null);
      this.subscribeToUser();
      this.subscribeToBasket();
    }
  }

  private subscribeToUser(): void {
    this.user$.subscribe((userData: UserModel) => {
      if (userData?.userId) {
        this.userId = userData.userId;
      }
      if (userData && Object.keys(userData).length === 0) {
        this.enqueueEvent(VisilabsEvents.userLogIn, {
          userId: this.userId,
          userLoginStatus: false
        });
      }
    });
  }

  private subscribeToBasket(): void {
    //subscribing to basket cause on basket update, visilab asks for the whole basket info..
    this.basket$.pipe(skip(2)).subscribe(basketData => {
      this.basket = basketData.data;
      console.log('movida basketUpdateAndView 1', basketData);
      this.enqueueEvent(VisilabsEvents.basketUpdateAndView, {});
    });
  }
  public loadVisilabsScript(): void {
    const scriptUrl =
      (location.protocol.indexOf('https') === 0 ? 'https' : 'http') +
      '://vsh.visilabs.net/Visilabs.js?sid=51712F34306754354F7A383D&oid=327965475944694835466B3D';
    const debugUrl =
      (location.protocol.indexOf('https') === 0 ? 'https' : 'http') +
      '://vsh.visilabs.net/Visilabs_Debug.js?sid=51712F34306754354F7A383D&oid=327965475944694835466B3D';
    const scriptSrc = location.href.toString().indexOf('vldebug=true') > 0 ? debugUrl : scriptUrl;

    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'type', 'text/javascript');
    this.renderer.setAttribute(script, 'src', scriptSrc);

    script.onload = () => {
      try {
        this.markScriptAsLoaded();
      } catch (ex) {
        console.error('Error in onVisilabsLoaded:', ex);
      }
    };

    const head = this.renderer.selectRootElement('head', true);
    this.renderer.appendChild(head, script);
  }

  // TO BE CONSIDERED
  // detailed page ngOnInit call is called twice. added  product.type == '[PRODUCT] GetProductByIdSuccess' to check where product is coming from...
  // productPageView gets 10 as an product Quantity!!

  // this has to be detailed
  // DetailedPageGuard
  private productPageView(args: {
    productId: string;
    productName: string;
    numberOfItemsInStock: number;
    productPrice: number;
    productBrand: string;
  }): void {
    console.log('movida productPageView', args);
    const VL = new (window as any).Visilabs();
    VL.AddParameter('OM.pv', args.productId.toString());
    VL.AddParameter('OM.pn', args.productName);
    VL.AddParameter('OM.inv', args.numberOfItemsInStock.toString());
    VL.AddParameter('OM.ppr', args.productPrice.toString());
    VL.AddParameter('OM.pv.1', args.productBrand);
    VL.Collect();
    VL.SuggestActions();
  }

  private categoryPageView(args: { categoryId: string; categoryName: string }): void {
    console.log('movida categoryPageView', args);
    const VL = new (window as any).Visilabs();
    VL.AddParameter('OM.clist', args.categoryId);
    VL.AddParameter('OM.cname', args.categoryName);
    VL.Collect();
    VL.SuggestActions();
  }

  private searchResultPageView(args: { searchKeyword: string; numberOfResult: number }): void {
    console.log('movida searchResultPageView', args);
    const VL = new (window as any).Visilabs();
    VL.AddParameter('OM.OSS', args.searchKeyword);
    VL.AddParameter('OM.OSSR', args.numberOfResult);

    VL.Collect();
    VL.SuggestActions();
  }

  private userLogIn(args: { userId: string; userLoginStatus: boolean }): void {
    console.log('movida userLogIn', this.userId);
    if (this.userRegisered) {
      this.enqueueEvent(VisilabsEvents.userSignUp, {
        userId: args.userId
      });
      this.userRegisered = false;
    }
    const VL = new (window as any).Visilabs();
    VL.AddParameter('OM.exVisitorID', args.userId);
    VL.AddParameter('OM.b_login', args.userLoginStatus ? 1 : 0);
    VL.Collect();
  }

  private userSignUp(args: { userId: string }): void {
    console.log('movida userSignUp', args);
    const VL = new (window as any).Visilabs();
    VL.AddParameter('OM.exVisitorID', args.userId);
    VL.AddParameter('OM.b_sgnp', '1');
    VL.Collect();
  }

  private basketUpdateAndView(): void {
    //in both cases when basket is viewed or updated this method is triggered
    if (this.basket === undefined) {
      return;
    }
    let productIds = [],
      productQuantities = [],
      productPriceSums = [];
    this.basket?.forEach(element => {
      productIds.push(element.productId);
      productQuantities.push(element.productCount);
      productPriceSums.push((element.discountedPrice || element.price) * element.productCount);
    });
    const VL = new (window as any).Visilabs();

    console.log('movida basketUpdateAndView 55', this.basket, productIds, productQuantities, productPriceSums);
    VL.AddParameter('OM.pbid', 'Basket ID');
    VL.AddParameter('OM.pb', productIds.toString());
    VL.AddParameter('OM.pu', productQuantities.toString());
    VL.AddParameter('OM.ppr', productPriceSums.toString());

    VL.Collect();
    VL.SuggestActions();
  }

  private wishlistUpdate(args: { item: any; isAdded: boolean }): void {
    console.log('movida wishlistUpdate', args);
    // +
    const VL = new (window as any).Visilabs();
    VL.AddParameter('OM.pf', args.item.id);
    VL.AddParameter('OM.pfu', args.isAdded ? '1' : '-1');
    VL.AddParameter('OM.ppr', args.item.discountedPrice || args.item.sellPrice);
    VL.Collect();
    VL.SuggestActions();
  }

  private bannerDisplay(args: { bannerName: string }): void {
    console.log('movida bannerDisplay', args);
    // +
    const VL = new (window as any).Visilabs();
    const bannerNameWithoutSpace = args.bannerName.replace(/\s+/g, '');
    VL.AddParameter('OM.OSBD', bannerNameWithoutSpace);
    VL.Collect();
  }

  private bannerClick(args: { bannerName: string }): void {
    console.log('movida bannerClick', args);
    // +
    const VL = new (window as any).Visilabs();
    const bannerNameWithoutSpace = args.bannerName.replace(/\s+/g, '');
    VL.AddParameter('OM.OSB', bannerNameWithoutSpace);
    VL.Collect();
  }

  private genericPageView(): void {
    console.log('movida genericPageView');
    // +
    const VL = new (window as any).Visilabs();
    VL.Collect();
  }
  // ---------------------------------------------------------------------------------------- evenet handling
  public enqueueEvent(name: VisilabsEvents, args?: any) {
    if (!environment.production) {
      return;
    }
    if (this.scriptLoaded) {
      this.handleEvent(name, args);
    } else {
      this.events.push({ name, args });
    }
  }

  private markScriptAsLoaded() {
    this.scriptLoaded = true;
    this.processEvents();
  }

  private processEvents() {
    this.events.forEach(event => this.handleEvent(event.name, event.args));
    this.clearEvents();
  }

  private handleEvent(name: VisilabsEvents, args: any) {
    switch (name) {
      case VisilabsEvents.productPageView:
        this.productPageView(args);
        break;
      case VisilabsEvents.categoryPageView:
        this.categoryPageView(args);
        break;
      case VisilabsEvents.searchResultPageView:
        this.searchResultPageView(args);
        break;
      case VisilabsEvents.userLogIn:
        this.userLogIn(args);
        break;
      case VisilabsEvents.userSignUp:
        this.userSignUp(args);
        break;
      case VisilabsEvents.basketUpdateAndView:
        this.basketUpdateAndView();
        break;
      case VisilabsEvents.wishlistUpdate:
        this.wishlistUpdate(args);
        break;
      case VisilabsEvents.bannerClick:
        this.bannerClick(args);
        break;
      case VisilabsEvents.bannerDisplay:
        this.bannerDisplay(args);
        break;
      case VisilabsEvents.genericPageView:
        this.genericPageView();
        break;
      default:
    }
  }

  private clearEvents() {
    this.events = [];
  }
}
